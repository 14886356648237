<template>
	<div class="email-template-setting">
		<v-layout class="py-3 px-3">
			<v-flex class="">
				<div class="fw-600 sub-heading primary--text">Email Template</div>
			</v-flex>
			<v-spacer></v-spacer>
			<!-- <v-flex class="d-flex justify-content-end">
				<v-btn class="" dense tile>
					<v-icon class="ml-1">mdi-arrow-left</v-icon>
					Back
				</v-btn>
			</v-flex> -->
		</v-layout>
		<div class="border pb-3 mb-5">
			<v-layout class="py-2 px-2 blue lighten-5">
				<v-flex class="">
					<div class="fw-500 sub-heading primary--text text-capitalize">Doing the job</div>
				</v-flex>
				<v-spacer></v-spacer>
				<v-flex class="d-flex justify-content-end"> </v-flex>
			</v-layout>
			<div class="pa-3 border-top" v-for="(template, index) in emailTemplate" :key="index">
				<div class="d-flex align-items-center py-2">
					<div class="fw-600 mr-5" v-if="template">{{ template.template }}</div>
					<div>
						<v-switch
							v-model="template.activated"
							inset
							hide-details
							class="my-0"
							color="green"
							v-on:change="updateEmailTemplateStatus(template.id, $event)"
						>
						</v-switch>
					</div>
				</div>
				<div class="d-flex align-items-center py-2">
					<div class="fw-500 mr-5" v-if="template">
						{{ template.description }}
					</div>
					<v-spacer></v-spacer>
					<div class="d-flex justify-content-end">
						<v-btn
							color="blue darken-4 white--text"
							tile
							depressed
							v-on:click="editEmailTemplate(template.id)"
							>Edit</v-btn
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "EmailTemplate",
	data() {
		return {
			visit_switch: false,
			emailTemplate: [],
		};
	},
	methods: {
		getEmailTemplateSetting() {
			this.$store
				.dispatch(GET, { url: `setting/email-template` })
				.then((data) => {
					this.emailTemplate = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		editEmailTemplate(id) {
			this.$router.push({
				name: "email-template-update",
				params: { id },
			});
		},
		updateEmailTemplateStatus(id, status) {
			this.pageLoading = true;
			this.$store
				.dispatch(PATCH, {
					url: `setting/email-template/${id}/status/${+!!status}`,
					data: this.emailTemplate,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Email setting updated successfully." },
					]);
					this.$router.push({
						name: "setting",
						params: { tab: "email_template" },
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.getEmailTemplateSetting();
	},
};
</script>
